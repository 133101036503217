/* Best Seller Section Styling */
.best-seller-section {
    padding: 20px;
    
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    color: #333; /* Text color */
    position: relative; /* Position relative for absolute positioning of buttons */
   
  }
  
  /* Section Title */
  .section-title {
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 28px; /* Slightly larger font size for the title */
    color: #e91e63; /* Vibrant pink color */
    transition: color 0.3s ease; /* Transition effect for text color */
  }
  
  .section-title:hover {
    color: #d81b60; /* Darker shade of pink on hover */
  }
  
  /* Product Container Styling */
  .product-container {
    display: flex;
    overflow-x: auto; /* Horizontal scroll if needed */
    gap: 20px; /* Space between cards */
    padding: 10px 0; /* Padding on top and bottom */
    scroll-behavior: smooth; /* Smooth scrolling behavior */
    position: relative; /* Relative positioning for the buttons */
  }
  
  /* Product Wrapper for Positioning */
  .product-wrapper {
    position: relative;
    flex: 0 0 auto; /* Prevent card from shrinking */
    width: 300px; /* Fixed width for cards */
  }
  
  /* Best Seller Tag Styling */
  .best-seller-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #ff4081; /* Vibrant pink background */
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    z-index: 10;
  }
  
  /* Product Card Styling */
  .product-card {
    border: none;
    border-radius: 15px; /* Add border radius to the card */
    background: linear-gradient(to bottom right, #fbd786, #f7797d, #c6ffdd); /* Vibrant gradient */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease; /* Smooth transition for multiple properties */
    position: relative; /* Ensure position relative for absolute positioning of the tag */
  }
  
  /* Zoom Effect on Hover */
  .product-card:hover {
    transform: scale(1.05); /* Slightly enlarge the card */
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
  }
  
  /* Product Image Styling */
  .product-card img {
    border-top-left-radius: 15px; /* Ensure image corners match card */
    border-top-right-radius: 15px;
    height: 250px; /* Increased image height */
    width: 100%; /* Full width to fit container */
    object-fit: cover; /* Ensure image covers area */
    transition: opacity 0.3s ease; /* Transition effect for image opacity */
  }
  
  /* Product Body Styling */
  .product-card .card-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 250px); /* Adjust height to fill remaining space */
    justify-content: space-between; /* Space out content evenly */
  }
  
  /* Product Tags Container */
  .product-tags {
    position: relative; /* Make sure the container is positioned relative for absolute positioning */
    display: flex;
    flex-wrap: wrap; /* Allow tags to wrap if necessary */
    gap: 5px; /* Space between tags */
  }
  
  /* Tag Badge Styling */
  .tag-badge {
    background-color: #0e190d; /* Color for tags */
    color: white;
    font-size: 12px; /* Font size for tags */
    padding: 5px 10px; /* Padding around tag text */
    border-radius: 12px; /* Rounded corners for badges */
    transition: background-color 0.3s ease; /* Transition effect for background color */
  }
  
  .tag-badge:hover {
    background-color: #e91e63; /* Darker pink on hover */
  }
  
  /* Price Badge Styling */
  .price-badge {
    position: absolute;
    right: 0; /* Align to the right */
    bottom: -25px; /* Slightly below the other tags */
    background-color: #ff5722; /* Distinct color for price */
    color: white;
    font-size: 12px; /* Match font size with other tags */
    padding: 5px 10px;
    border-radius: 12px; /* Match border radius with other tags */
  }
  
  /* Product Price Styling */
  .product-price {
    display: none; /* Hide the price here; shown as a tag instead */
  }
  
  /* Product Description Styling */
  .product-description {
    max-height: 100px; /* Limit description height */
    overflow: hidden; /* Hide overflow content */
    position: relative; /* Relative positioning for Read More tag */
    transition: max-height 0.3s ease; /* Smooth transition for expanding */
  }
  
  /* Expanded Description Styling */
  .product-description.expanded {
    max-height: 300px; /* Expanded height to show full content */
  }
  
  /* Read More Tag Styling */
  .read-more-tag {
    color: #e91e63;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    transition: color 0.3s ease; /* Transition effect for color */
  }
  
  .read-more-tag:hover {
    color: #d81b60; /* Darker shade of pink on hover */
  }
  
  /* Scroll Buttons Styling */
  .scroll-buttons {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .scroll-button {
    background: rgba(0, 0, 0, 0.3);
    border: none;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    transition: background 0.3s ease;
  }
  
  .scroll-button:hover {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .scroll-button.left {
    margin-left: 10px;
  }
  
  .scroll-button.right {
    margin-right: 10px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 767px) {
    .section-title {
      font-size: 24px; /* Adjust title font size for smaller screens */
    }
  
    .product-card {
      width: 250px; /* Adjust width for smaller screens */
    }
  
    .product-price {
      font-size: 16px; /* Adjust price font size for smaller screens */
    }
  
    .tag-badge {
      font-size: 10px; /* Adjust font size for smaller screens */
      padding: 4px 8px; /* Adjust padding for smaller screens */
    }
  
    .price-badge {
      font-size: 10px; /* Match font size for smaller screens */
      padding: 4px 8px; /* Adjust padding for smaller screens */
    }
  }
  