/* Overall Page Styling */
.home-container {
  background-color: #fdf6e3; /* Light background color for the entire page */
  padding: 0; /* Remove padding from container */
}


.announcement-text {
  color: #333333; /* Darker text color for better contrast */
  font-family: 'Cursive', sans-serif;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-align: center;
  display: block;
}

/* Slider Section */
.slider-row {
  padding: 15px 20px;
  background: linear-gradient(to right, #ffecd2, #fcb69f); /* Consistent gradient across sections */
  
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 0; /* Remove bottom margin */
}

.slider-row .col-lg-12 {
  padding: 0;
}

/* Best Seller Section */
.bestseller-row {
  padding: 20px 25px;
  background: linear-gradient(to left, #667EEA, #764BA2);
 
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 0; /* Remove bottom margin */
}

.bestseller-row .col-lg-12 {
  padding: 0;
}



.footer {
  text-align: center;
}

.footer-text {
  color: #333333;
  font-size: 14px;
  line-height: 1.6;
}

/* Media Queries for Responsive Design */
@media (max-width: 767px) {
  .announcement-text {
    font-size: 24px;
  }

  .slider-row, .bestseller-row {
    padding: 10px 15px;
  }

  .footer-text {
    font-size: 13px;
  }
}
