@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Global Font and Size */
body {
  font-family: 'Poppins', sans-serif; /* Replace with your desired font */
  font-size: 16px; /* Base font size */
  color: #333; /* Default text color */
  line-height: 1.6; /* Default line height for readability */
  margin: 0;
  padding: 10px;
  background: linear-gradient(to right, #f6d365, #fda085);
  box-sizing: border-box;
  
}

/* Additional Global Resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 100%; /* Set to 16px by default */
  box-sizing: border-box;
}

/* Overall Page Styling */
.home-container {
  background-color: #fff; /* Light background color for the content area */
  
}

/* Marquee Section */
.announcement-marquee {
 
  background: linear-gradient(to right, #d8a1a1, #f58c8c); /* Soft rose gradient with a hint of darkness */
  background-blend-mode: overlay;
}

.announcement-text {
  color: #333333; /* Darker text color for better contrast */
  font-family: 'Cursive', sans-serif; /* Specific font for the announcement */
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-align: center;
  display: block;
}

/* Slider Section */
.slider-row {
 
  
  background: linear-gradient(to right, #d5a8b3, #e6c3e4); /* Darker pastel gradient with a touch of elegance */

  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1), transparent), 
                    linear-gradient(to right, #d5a8b3, #e6c3e4);
  background-blend-mode: overlay;
}

.slider-row .col-lg-12 {
  padding: 0;
}

/* Best Seller Section */
.bestseller-row {
  
  
  background: linear-gradient(to right, #d5a8b3, #e6c3e4); /* Darker pastel gradient with a touch of elegance */
 
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1), transparent), 
                    linear-gradient(to right, #d5a8b3, #e6c3e4);
  background-blend-mode: overlay;
}

.bestseller-row .col-lg-12 {
  padding: 0;
}

/* Footer Section */
.footer-row {
  background: linear-gradient(to right, #d8a1a1, #f58c8c); /* Soft rose gradient with a hint of darkness */
  padding: 30px 0;
  
  
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1), transparent), 
                    linear-gradient(to right, #d8a1a1, #f58c8c);
  background-blend-mode: overlay;
}

.footer {
  text-align: center;
}

.footer-text {
  color: #333333; /* Darker text color for better contrast */
  font-size: 14px; /* Smaller font size for footer */
 
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  body {
    font-size: 14px; /* Adjust base font size for smaller screens */
  }
}
