.about-container {
  font-family: Arial, sans-serif;
  margin: 10px;
}

/* Common styles for all sections */
.about-row {
  padding: 40px 0;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  margin: 0; /* Remove default margin between rows */
}

.about-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.about-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.about-image-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.about-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Section-specific styles with gradients */
.story-section {
  background: linear-gradient(to right, #9F7AEA, #ED64A6);
}

.philosophy-section {
  background: linear-gradient(to left, #667EEA, #764BA2);
}

.diversity-section {
  background: linear-gradient(to right, #FF6B6B, #556270);
}

.gifting-section {
  background: linear-gradient(to left, #43C6AC, #191654);
}

/* For reverse layout in even sections */
.order-md-2 {
  order: 2;
}

.address-founders-section {
  background: linear-gradient(to right, #da4716cd, #96e6a1);
}

.address {
  margin-bottom: 20px;
}

.address-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.address-text {
  font-size: 16px;
}


.contact-info {
  
  margin-top: 20px;
}

.contact-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.contact-item {
  font-size: 16px;
  margin-bottom: 10px;
}

.contact-item svg {
  margin-right: 8px;
}

.contact-section{
  background: linear-gradient(to right, #2a1404, #fda085); /* Gradient background */
}
.about-image-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.founder-section {
  background: linear-gradient(to right, #f6d365, #fda085); /* Gradient background */
  padding: 40px 20px;
 
}

.founder-card {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.founder-image {
  width: 100%;
  height: auto;
  max-width: 200px;
  border-radius: 5%;
 
 
}

.founder-info {
  color: gray;
  flex: 1;
  font-weight: 600;
}

.founder-info h3 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  padding-left:20px
}

.founder-info p {
  margin: 0;
  font-size: 1.1em;
  font-weight: 600;
  padding-left:20px
}

