.navbar-logo {
    max-height: 200px; /* Increased size */
    width: auto;       /* Maintain aspect ratio */
    object-fit: contain;
   
  }

.navbar{
    padding: 0;
    
    
}

.nav-text{
    color: white;
}

.dropdown-menu{
    background: linear-gradient(to right, #9F7AEA, #ED64A6)
}

.navbar-nav .nav-link,
.navbar-nav .dropdown-item {
  transition: 'background 0.3s ease-in-out'; /* Smooth transition */
  border-radius: 20px;
}

.navbar-nav .nav-link:hover,
.navbar-nav .dropdown-item:hover {
  background: linear-gradient(to right, #9F7AEA, #ED64A6);
  color: white; /* Optional: Change text color */
  border-radius: 20px;
}

