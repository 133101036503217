.card-custom {
    border-radius: 15px; /* Rounded corners for the entire card */
    background: linear-gradient(to right, #9F7AEA, #ED64A6); /* Gradient background */
    color: white; /* Text color */
    overflow: hidden; /* Ensure content respects rounded corners */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1), /* General shadow */
                0 15px 25px rgba(0, 0, 0, 0.2); /* Deeper shadow for 3D effect */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transition */
  }
  
  .card-custom:hover {
    transform: translateY(-10px) scale(1.05); /* Slight lift on hover */
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2), /* Increase shadow on hover */
                0 25px 50px rgba(0, 0, 0, 0.25); /* Increase depth shadow on hover */

  }
  
  .card-custom .card-img-top {
    border-radius: 25px 25px 0 0; /* Rounded corners for the top image */
  }
  
  .card-custom .card-body {
    border-radius: 0 0 25px 25px; /* Rounded corners for the bottom of the card */
  }
  
  /* Product.css */
.full-width {
    width: 100%;
  }
  
  .checkbox-container {
    display: flex;
    justify-content: flex-end; /* Aligns the checkbox to the right */
    padding: 1rem; /* Adjust padding as needed */
  }